'use strict'

angular
	.module('cb.search', [])
	.controller('SearchCustomersController', ["$scope", "$rootScope", "SearchService", "MaintenanceService", "PickListService", "NotificationService", "LeadProviderService", "LeadProfileService", "$location", function ($scope, $rootScope, SearchService, MaintenanceService, PickListService, NotificationService, LeadProviderService, LeadProfileService, $location) {

		$scope.init = function () {
			$scope.OrderSearch = [];
			$scope.searchResults = [];
			$scope.Search = {};
			$scope.checkboxModel = {
				value: false,
			};

			$scope.viewAllPermission = false;
			if ($rootScope.User.Roles != undefined)
				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Fitter' || r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;

			$scope.searchPageNumber = 1;
			$scope.searchPagination = {};
			$scope.searchAttempts = 0;

			$scope.IsSalesperson
			if ($rootScope.User.Roles != undefined)
				$scope.IsSalesperson = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0 ? true : false;

			$scope.GetAllStatusPromise = MaintenanceService.getAllStatuses();
			$scope.GetAllStatusPromise.then(function (output) {
				$scope.LeadStatusData = output.data.StatusList;
			}, function (error) {
				NotificationService.alert('Lead Status Error', 'There was an error retriving the lead status.', 'alert', {});
			});

			$scope.GetActionPickListPromise = MaintenanceService.getAllActions();
			$scope.GetActionPickListPromise.then(function (output) {
				$scope.ActionPickListData = output.data.ActionList;
			}, function (error) {
				NotificationService.alert('Action Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});

			$scope.GetAllStatusGroupPromise = MaintenanceService.getAllStatuseGroups();
			$scope.GetAllStatusGroupPromise.then(function (output) {
				$scope.LeadStatusGroupData = output.data.StatusGroupList;
			}, function (error) {
				NotificationService.alert('Lead Status Group Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});

			$scope.GetLeadProviderPromise = LeadProviderService.getAllLeadProviders();
			$scope.GetLeadProviderPromise.then(function (output) {
				$scope.LeadProviderData = output.data.LeadProviders;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
			});
			$scope.LastSearchedPostcode = "";
			$scope.OutOfArea = false;
			$scope.Special = false;
		};

		$scope.addToBulk = function (item) {
			$scope.OrderSearch.push(item)
		};

		$scope.removeFromBulk = function (index) {
			$scope.OrderSearch.splice(index, 1);
		};
		$scope.ShowStatusGroup = true;
		$scope.$watch('Search.Validated.SelectedStatus', function () {
			//alert(JSON.stringify($scope.Search.Validated.SelectedStatus));
			if ($scope.Search.Validated.SelectedStatus.Name == 'Prospect')
				$scope.ShowStatusGroup = false;
			else
				$scope.ShowStatusGroup = true;
		});

		$scope.bulkSearch = function (bulkSearch) {
			$scope.currentPage = 0;
			$scope.BulkSeachResultPromise = SearchService.BulkSearch(bulkSearch);
			$scope.BulkSeachResultPromise.then(function (output) {
				$scope.BulkSeachResult = output.data.SearchItems;
			}, function (err) {
				console.error(err);
			});
		};

		$scope.AddNewEnquiry = function () {
			// Make a new Enquiry if SearchInfo has data

			if ($scope.Search.Validated) {
				var data = {
					Postcode: $scope.Search.Validated.Postcode
					/*Surname: $scope.Search.Validated.Surname,
					phone: $scope.Search.Validated.Phone,
					company: $scope.Search.Validated.Company,
					leadProvider:$scope.Search.Validated.SelectedLeadProvider,*/
				};
				$location.path('/enquiry-details/new').search(data);
			}
			else
				$location.path('/enquiry-details/new');

		};

		$scope.$watch('searchPageNumber', function (n, o) {
			getSearch();
		});

		$scope.search = function () {
			if ($scope.searchPageNumber == 1)
				getSearch();
			else
				$scope.searchPageNumber = 1;
		};

		function getSearch() {
			if ($scope.Search.Validated != undefined && $scope.Search.Validated.Postcode != undefined)
				$scope.LastSearchedPostcode = $scope.Search.Validated.Postcode

			if ($scope.searchAttempts > 0) {
				var refs = $scope.Search.Validated.RefNumber;
				if (refs && refs.length) {
					refs.trim();
					if (refs.slice(-1) == ',')
						refs = refs.slice(0, -1);
					$scope.Search.Validated.RefNumber = refs.split(',');
				};

				$scope.Search.Pagination = {
					StartPage: $scope.searchPageNumber - 1,
					PageSize: 15
				};

				//REF = 1	0000000001		Search.Validated.RefNumber					0	1
				//SUR = 2	0000000010		Search.Validated.Surname					0	1
				//PHN = 4	0000000100		Search.Validated.Phone						0	1
				//STS = 8	0000001000		Search.Validated.SelectedStatus				0	1 { Not 0 an Id }
				//LEP = 16	0000010000		Search.Validated.SelectedLeadProvider		0	1 { Not 0 an Id }
				//PCD = 32	0000100000		Search.Validated.Postcode					0	1
				//CMP = 64	0001000000		Search.Validated.Company					0	1
				//EML = 128	0010000000		Search.Validated.Email						0	1
				//SGP = 256	0100000000		Search.Validated.SelectedStatusGroup		0 	1 { Live X Pre - Sales Sold Unsold }

				$scope.Search.SearchMode = 0;

				if ($scope.Search.Validated.RefNumber != null && $scope.Search.Validated.RefNumber.length>0)
					$scope.Search.SearchMode += 1;

				if ($scope.Search.Validated.Surname != null && $scope.Search.Validated.Surname.length > 0)
					$scope.Search.SearchMode += 2;

				if ($scope.Search.Validated.Phone != null && $scope.Search.Validated.Phone.length > 0)
					$scope.Search.SearchMode += 4;
				
				if ($scope.Search.Validated.SelectedStatus != null && $scope.Search.Validated.SelectedStatus != undefined)
					$scope.Search.SearchMode += 8;

				if ($scope.Search.Validated.SelectedLeadProvider != null && $scope.Search.Validated.SelectedLeadProvider != undefined)
					$scope.Search.SearchMode += 16;

				if ($scope.Search.Validated.Postcode != null && $scope.Search.Validated.Postcode.length > 0)
					$scope.Search.SearchMode += 32;

				if ($scope.Search.Validated.Company != null && $scope.Search.Validated.Company.length > 0)
					$scope.Search.SearchMode += 64;

				if ($scope.Search.Validated.Email != null && $scope.Search.Validated.Email.length > 0)
					$scope.Search.SearchMode += 128;

				if ($scope.Search.Validated.SelectedStatusGroup != null && $scope.Search.Validated.SelectedStatusGroup != undefined)
					$scope.Search.SearchMode += 256;

				$scope.SeachResultPromise = SearchService.searchCustomer($scope.Search);
				$scope.SeachResultPromise.then(function (output) {
					$scope.Special = output.data.Special;
					$scope.OutOfArea = output.data.OutOfArea;
					$scope.displaySearchResults = true;
					$scope.searchResults = output.data.SearchResult;
					$scope.searchPagination = output.data.Pagination;
					$scope.numberOfPages = function () {
						return Math.ceil($scope.searchResults.length / $scope.pageSize);
					};
					if ($scope.Search.Validated.RefNumber)
						$scope.Search.Validated.RefNumber = $scope.Search.Validated.RefNumber.join(', ');
				});
			} else
				$scope.searchAttempts++;
		};

		$scope.reset = function () {
			delete $scope.displaySearchResults;
		};

		$scope.increment = function () {
			$scope.currentPage++;
		};

		$scope.decrement = function () {
			$scope.currentPage--;
		};
	}])

	.controller('SearchSalesOrdersController', ["$scope", "$rootScope", "PickListService", "SearchService", "MaintenanceService", function ($scope, $rootScope, PickListService, SearchService, MaintenanceService) {

		$scope.GetActionPickListPromise = MaintenanceService.getAllActions();
		$scope.GetActionPickListPromise.then(function (output) {
			$scope.ActionPickListData = output.data.ActionList;
		}, function (error) {
			NotificationService.alert('Action Error', 'There was an error loading some information for this page, please try refreshing.', 'alert', {});
		});

		$scope.currentPage = 0;
		$scope.pageSize = 10;
		$scope.searchResults = [];

		$scope.search = function () {
			//RESTRICT SEARCH IF USER IS FITTER OR SALESPERSON
			$scope.Search.ClosedSearch = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Fitter' || r.Name == 'Sales Person/Fitter').length > 0 ? true : false;
			if ($scope.Search.ClosedSearch)
				$scope.Search.UserType = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0 ? 'Sales Person' : $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0 ? 'Fitter' : 'Sales Person/Fitter';
			$scope.Search.UserId = $rootScope.User.Id;

			$scope.displaySearchResults = true;
			$scope.SeachResultPromise = SearchService.searchSaleOrder($scope.Search);
			$scope.SeachResultPromise.then(function (output) {
				$scope.displaySearchResults = true;
				$scope.searchResults = output.data.SearchResult;
				$scope.numberOfPages = function () {
					return Math.ceil($scope.searchResults.length / $scope.pageSize);
				}
			});
		};

		$scope.reset = function () {
			delete $scope.displaySearchResults;
		};
	}])

	.controller('SearchSalesmenLeadsController', ["$scope", function ($scope) {
		$scope.searchResultsPage = 1;
		$scope.searchResultsNextPage = true;
		$scope.searchResults = [{
			LeadName: 'Bevan'
		},
		{
			LeadName: 'P Carter'
		},
		{
			LeadName: 'Claire Harvey'
		}
		];

		$scope.search = function () {
			$scope.displaySearchResults = true;
		};

		$scope.reset = function () {
			delete $scope.displaySearchResults;
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};
	}])

	.controller('SearchLeadProvidersController', ["$scope", "LeadProviderService", "NotificationService", "PickListService", "$location", function ($scope, LeadProviderService, NotificationService, PickListService, $location) {

		$scope.init = function () {
			$scope.Search = {
				NotValidate: {},
				Validate: {}
			};
			$scope.reportPageNumber = 1;
			$scope.pagination = {};
			$scope.execCounter = 0;
			$scope.CollectionPromise = PickListService.getPickListByPickListName(['LeadProviderStatus']);
			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = output.data.PickListEntries;
			});
		};

		$scope.$watch('reportPageNumber', () => {
			$scope.search();
		});

		$scope.AddNewLeadProvider = function () {
			if ($scope.Search.Validate) {
				var data = {
					Postcode: $scope.Search.Validate.Postcode
				};
				$location.path('/lead-provider-details/new').search(data);
			}
			else
				$location.path('/lead-provider-details/new');
		};

		$scope.search = function () {
			if ($scope.execCounter > 0) {
				var data = {}
				data.RefNumber = $scope.Search.Validate.RefNumber;
				data.Postcode = $scope.Search.Validate.Postcode;
				data.Surname = $scope.Search.Validate.Surname;
				data.Company = $scope.Search.Validate.Company;
				data.Status = $scope.Search.NotValidate.Status;

				data.IncludeDead = $scope.Search.NotValidate.Include;
				data.Pagination = {
					StartPage: $scope.reportPageNumber - 1,
					PageSize: 15
				};

				if ($scope.Search.NotValidate.Match == 'all')
					data.MatchAll = true;
				else
					data.MatchAll = false;

				$scope.SearchLeadProviderPromise = LeadProviderService.search(data);
				$scope.SearchLeadProviderPromise.then(function (output) {
					$scope.displaySearchResults = output.data.LeadProviders;
					$scope.pagination = output.data.PaginationStatus;
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Lead Provider Error', 'There was an error retrieving the search results.', 'error', {});
				});
			} else {
				$scope.execCounter++;
			};
		};

		$scope.reset = function () {
			delete $scope.displaySearchResults;
		};
	}])
	.controller('PostcodeSearchController', ["$rootScope", "$scope", "$modalInstance", "SearchService", "NotificationService", "$location", "data", function ($rootScope,$scope, $modalInstance, SearchService, NotificationService, $location, data) {

		$scope.init = function () {
			$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Fitter' || r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
			$scope.postcode = data.toUpperCase();
			$scope.searchResults = [];
			Search();
		};

		function Search() {
			var data = {
				term: $scope.postcode
			};

			$scope.GettingSearchPromise = SearchService.quickSearch2(data);

			$scope.GettingSearchPromise.then(function (output) {
				$scope.searchResults = output.data.SearchResult;
				$scope.OutOfArea = output.data.OutOfArea;
				$scope.Special = output.data.Special;

				if (!$scope.OutOfArea) {

					$scope.GettingSearchPromise2 = SearchService.quickSearch(data);
					$scope.GettingSearchPromise2.then(function (output1) {
						$scope.searchResults = output1.data.SearchResult;
						$scope.OutOfArea = output1.data.OutOfArea;
						$scope.Special = output.data.Special;
	
					}, function (error) {
						NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
					});
				}
				else {
					$scope.OutOfArea = true;
					$scope.GettingSearchPromise2 = SearchService.quickSearch(data);
					$scope.GettingSearchPromise2.then(function (output1) {
						$scope.searchResults = output1.data.SearchResult;
					}, function (error) {
						NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
					});
				}

			}, function (error) {
				NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
			});


		};

		$scope.viewRecord = function (col) {
			$modalInstance.dismiss();
			if (col.Type === 'Enquiry')
				$location.path('/lead-details/' + col.Id);
			else if (col.Type === 'Order')
				$location.path('/sales-order/' + col.Id);
			else
				$location.path('/prospect-details/' + col.Id);

		};

		$scope.AddNewEnquiry = function () {
			$location.path('/enquiry-details/new').search("Postcode=" + $scope.postcode.toUpperCase());
			$scope.close();
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.filter('startFrom', function () {
		return function (input, start) {
			start = +start;
			return input.slice(start);
		}
	})
	.filter('minLengthObject', function () {
		return function (input, length) {
			var output = false;

			if (input === undefined || typeof (length) != 'number')
				output = true;

			var count = 0;
			for (var k in input) {
				if (input[k] != null && input[k].length != 0) {
					count++;
					if (input[k].length < length)
						output = true;
				}
			};

			if (count == 0)
				output = true;

			return output;
		};
	})
	.controller('ProformaSearchController', ["$scope", "$modalInstance", "MessagesService", "NotificationService", "$location", "data", function ($scope, $modalInstance, MessagesService, NotificationService, $location, data) {

		$scope.init = function () {
			$scope.first = data;
			$scope.searchResults = [];
			Search();
		};

		function Search() {
			$scope.getProformaPromiseList = MessagesService.GetProformaList({ Pagination: { StartPage: 0, PageSize: 99999 }});
			$scope.getProformaPromiseList.then(output => {
				$scope.reminders = output.data.Reminders;
				$scope.OutOfArea = output.OutOfArea;
			}, function (error) {
				NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
			});
		};

		$scope.goToFirst = function ()
		{
			$modalInstance.dismiss();
			$location.path('/sales-order/' + $scope.first);
		};

		$scope.viewRecord = function (ref) {
			$modalInstance.dismiss();
			$location.path('/sales-order/' + ref);
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('GlobalSearchController', ["$scope", "$rootScope", "SearchService", "NotificationService", "orderByFilter", function ($scope, $rootScope, SearchService, NotificationService, orderByFilter) {

		// Correct permission?
		$scope.permission =
			$rootScope.User.Roles.filter(r => r.Name == 'Contracts'
				|| r.Name == 'Accounts'
				|| r.Name == 'Factory'
				|| r.Name == 'System Administrator'
				|| r.Name == 'Sales Desk'
				|| r.Name == 'Marketing'
				|| r.Name == 'Partnership'
				|| r.Name == 'Operations Manager').length > 0 ? true : false;

		if (!$scope.permission)
			window.location.href = '/#/customer-search';

		$scope.range = 100;
		$scope.saleorders = true;
		$scope.leaddates = false;
		$scope.comments = 0;
		$scope.alerts = false;
		$scope.appointments = false;
		$scope.notes = false;
		$scope.memos = false;
		$scope.currentPage = 0;
		$scope.pageSize = 10;
		$scope.searchResults = [];

		$scope.sortsetting = "Ref";
		$scope.reverse = false;

		$scope.drp_start = moment().subtract(1, 'weeks');
		$scope.drp_end = moment();
		$scope.drp_options = {
			timePicker: false,
			opens: 'left',
			startDate: $scope.drp_start,
			endDate: $scope.drp_end,
			format: 'DD/MM/YYYY',
			showDropdowns: true,
			ranges: {
				'This Week': [moment().startOf('week').add(1, 'day'), moment().endOf('week').add(1, 'day')],
				'Last Week': [moment().startOf('week').subtract(1, 'week').add(1, 'day'), moment().endOf('week').subtract(1, 'week').add(1, 'day')],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().startOf('month').subtract(1, 'month'), moment().subtract('month', 1).endOf('month')]
			}
		};

		$scope.sorting = function (mode) {
			$scope.sortsetting = mode;
			$scope.reverse = !$scope.reverse;
		};

		$scope.$watch('drp_start', function () {
			$scope.displayStartDate = moment($scope.drp_start).local().format('DD/MM/YYYY');
		});

		$scope.$watch('drp_end', function () {
			$scope.displayEndDate = moment($scope.drp_end).format('DD/MM/YYYY');
		});

		$scope.functionThatReturnsStyle = function (style) {
			return 'color:' + style + ';';
		}

		$scope.search = function () {

			var searchData = {
				Search: $scope.keyword,
				SaleOrders : $scope.saleorders,
				LeadDates: $scope.leaddates,
				Start: $scope.drp_start,
				End: $scope.drp_end,
				ResultsRange: $scope.range,
				CommentMode: $scope.comments,
				Alerts: $scope.alerts,
				Appointments: $scope.appointments,
				Notes: $scope.notes,
				Memos: $scope.memos,
				UserId: $rootScope.User.Id
			};

			$scope.GettingSearchPromise = SearchService.globalSearch(searchData);
			$scope.GettingSearchPromise.then(function (output) {
				$scope.searchResults = output.data.SearchResult;
				$scope.numberOfPages = function () {
					return Math.ceil($scope.searchResults.length / $scope.pageSize);
				};
			}, function (error) {
				NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
			});
		};
	}])
	.controller('GlobalSearchModalController', ["$scope", "$rootScope", "$modalInstance", "SearchService", "NotificationService", "data", function ($scope, $rootScope, $modalInstance, SearchService, NotificationService, data) {

		$scope.init = function () {
			$scope.keyword = data.Search;
			$scope.range = data.ResultsRange;
			$scope.comments = data.CommentMode;
			$scope.orders = data.Orders;
			$scope.notes = data.Notes;
			$scope.memos = data.Memos;
			$scope.currentPage = 0;
			$scope.pageSize = 10;
			$scope.searchResults = [];
			$scope.search();
		};

		$scope.search = function () {

			var searchData = {
				Search: $scope.keyword,
				ResultsRange: $scope.range,
				CommentMode: $scope.comments,
				Orders: $scope.orders,
				Notes: $scope.notes,
				Memos: $scope.memos,
				UserId: $rootScope.User.Id
			};
			
			$scope.GettingSearchPromise = SearchService.globalSearch(searchData);
			$scope.GettingSearchPromise.then(function (output) {
				$scope.searchResults = output.data.SearchResult;
				$scope.numberOfPages = function () {
					return Math.ceil($scope.searchResults.length / $scope.pageSize);
				};
			}, function (error) {
				NotificationService.alert('Search Error', 'Failed to retrieve the list for search parameter.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('GlobalSearchPropertiesController', ["$scope", "$modalInstance", "data", "$modal", function ($scope, $modalInstance, data, $modal) {
		
		$scope.init = function () {
			$scope.Data = {};
			$scope.Data.keyword = data.Search;
			$scope.Data.range = data.ResultsRange;
			$scope.Data.comments = data.CommentMode;
			$scope.Data.orders = data.Orders;
			$scope.Data.notes = data.Notes;
			$scope.Data.memos = data.Memos;
		};

		$scope.$watch('Data.comments', function () {
			if ($scope.Data.comments == 1) {
				$scope.Data.orders = false;
				$scope.Data.notes = false;
				$scope.Data.memos = false;
			}
		});

		$scope.goToSearchGlobal = function () {
			
			var searchData = {
				Search: $scope.Data.keyword,
				ResultsRange: $scope.Data.range,
				CommentMode: $scope.Data.comments,
				Orders: $scope.Data.orders,
				Notes: $scope.Data.notes,
				Memos: $scope.Data.memos
			};
			
			openModal('views/modal-templates/advanced-search.html', 'GlobalSearchModalController', searchData);
			
		};

		$scope.close = function () {
			$scope.Data = {};
			$modalInstance.dismiss();
		};

		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};
	}])
